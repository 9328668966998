export default {
  currEnv: 'qa',
  formSource: {
    CHECK_IN: "Check-In",
    REPORT: "Report"
  },
  maxQRCodeStrLength: 30,
  adminAccount: {
    ELOG_SUPPORT: 'support@tawitech.ph'
  },
}